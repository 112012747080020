import { useScriptMetaPixel, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const metaPixel = useScriptMetaPixel({"id":"422280694773923"})
    return { provide: { $scripts: { metaPixel } } }
  }
})
// middleware/clientLocale.js
import { defineNuxtRouteMiddleware, navigateTo } from '#app';
import Cookies from 'js-cookie';
import Utility from '~/server/utils/utils'; // Ensure Utility functions are correctly implemented

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) return; // Only run on client-side
  const localePath = useLocalePath();

  const currentPath = to.fullPath;
  const localeMap = {
    en: '/',
    fr: '/fr',
    es: '/es',
    de: '/de',
    it: '/it',
    nl: '/nl',
    pl: '/pl',
    pt: '/pt',
  };

  // Check if the current path is the homepage
  const isHomepage = currentPath === '/' || Object.values(localeMap).includes(currentPath);

  if (isHomepage) {
    // Development-only utility function test
    if (process.dev) {
      Utility.testSComposeURL();
    }

    // Supported locales from localeMap
    const supportedLocales = Object.keys(localeMap);
    const defaultLocale = 'en';

    // Retrieve preferred locale from cookies
    const preferredLanguageLocale = Cookies.get('preferredLanguageLocale');

    // Extract current locale from the URL using Utility.scomposeURL
    const utilityResult = Utility.scomposeURL(currentPath);
    let currentLocale = utilityResult?.result?.lang || defaultLocale;

    // Function to detect browser language
    const getBrowserLocale = () => {
      const browserLang = navigator.language.split('-')[0].toLowerCase();
      return supportedLocales.includes(browserLang) ? browserLang : defaultLocale;
    };

    // If we reach here, the path doesn't have a locale prefix
    // Use the preferred locale from cookie or fall back to browser language
    const targetLocale = preferredLanguageLocale || getBrowserLocale();

    if (currentLocale !== targetLocale) {
      return navigateTo(localeMap[targetLocale]);
    }
  }
});
